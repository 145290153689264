<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!--begin: My Cart -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
        >
          <span class="svg-icon svg-icon-xl">
            <!--new icon-->
            <i class="flaticon2-shopping-cart-1 text-success cart" />
          </span>
          <span class="navi-link-badge">
            <span class="label label-white label-rounded font-weight-bold">
              {{ getTotalOfItems }}
            </span>
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" style="width: 350px;">
        <KTDropdownMyCart></KTDropdownMyCart>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: My Cart -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .cart {
    font-size: 2rem !important;
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTDropdownMyCart
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    ...mapGetters(["cart", "business"]),

    getLanguageFlag() {
      return this.onLanguageChanged();
    },

    getTotalOfItems() {
      let items = 0;

      for (let line of this.cart.lines) {
        items += line.quantity;
      }

      return items;
    }
  }
};
</script>
