<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    v-bind:class="headerClasses"
  >
    <!--begin::Logo-->
    <router-link :to="`/${businessCode}`">
      <div
              v-if="businessLogo">
        <img
                alt="Logo"
                :src="businessLogo"
                class="logo-default max-h-30px"
        />
      </div>
      <div v-else>
        <img alt="Logo" :src="layoutConfig('self.logo.default')" class="logo-default max-h-30px" />
      </div>
    </router-link>
    <!--end::Logo-->
    <!--begin::Toolbar-->
    <div class="d-flex align-items-center">
      <!--begin::Aside Mobile Toggle-->
      <button
        v-if="asideEnabled"
        class="btn p-0 burger-icon burger-icon-left"
        id="kt_aside_mobile_toggle"
      >
        <span></span>
      </button>
      <!--end::Aside Mobile Toggle-->
      <!--begin::Header Menu Mobile Toggle-->
      <!--      <button-->
      <!--        class="btn p-0 burger-icon ml-4"-->
      <!--        id="kt_header_mobile_toggle"-->
      <!--        ref="kt_header_mobile_toggle"-->
      <!--      >-->
      <!--        <span></span>-->
      <!--      </button>-->
      <!--end::Header Menu Mobile Toggle-->
      <!--begin::Topbar Mobile Toggle-->
      <span class="svg-icon svg-icon-xl">
        <router-link :to="`/${businessCode}/cart`">
          <div
            class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1"
          >
            <span class="svg-icon svg-icon-xl">
              <inline-svg src="/media/svg/icons/Shopping/Cart3.svg" />
            </span>
          </div>
        </router-link>
      </span>
      <!--      <button-->
      <!--        class="btn btn-icon btn-hover-transparent-white p-0 ml-3"-->
      <!--        id="kt_header_mobile_topbar_toggle"-->
      <!--        ref="kt_header_mobile_topbar_toggle"-->
      <!--      >-->
      <!--        <span class="svg-icon svg-icon-xl">-->
      <!--          <router-link :to="`/${businessCode}/cart`">-->
      <!--            <inline-svg class="svg-icon" src="/media/svg/icons/Shopping/Cart3.svg" />-->
      <!--          </router-link>-->
      <!--        </span>-->
      <!--      </button>-->
      <!--end::Topbar Mobile Toggle-->
    </div>
    <!--end::Toolbar-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "business"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.VUE_APP_URL + this.layoutConfig("self.logo.default");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    businessCode() {
      if (this.business) {
        return this.business.code;
      } else {
        return "";
      }
    },
    businessLogo() {
      if (this.business && this.business.imageId) {
        return `${process.env.VUE_APP_API_URL}/media/${this.business.imageId}`;
      } else {
        return null;
      }
    }
  }
};
</script>
