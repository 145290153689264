<template>
  <form>
    <!--begin::Header-->
    <div
      class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
        <i class="flaticon2-shopping-cart-1 text-success" />
      </span>
      <h4 class="text-white m-0 flex-grow-1 mr-3">Mi Carrito</h4>
      <button type="button" class="btn btn-success btn-sm">
        {{ cart.lines.length }} Artículos
      </button>
    </div>
    <!--end::Header-->

    <div class="scroll scroll-push">
      <perfect-scrollbar
        class="scroll"
        style="max-height: 45vh; position: relative;"
      >
        <!--begin::Item-->
        <template v-for="(item, i) in cart.lines">
          <div
            class="d-flex align-items-center justify-content-between p-8"
            v-bind:key="i"
          >
            <div class="d-flex flex-column mr-2">
              <a
                href="#"
                class="font-weight-bold text-dark-75 font-size-lg text-hover-primary"
              >
                {{ item.product.title }}
              </a>
              <span class="text-muted">
                {{ item.product.description }}
              </span>
              <div class="d-flex align-items-center mt-2">
                <QuantitySelector :line="item"></QuantitySelector>
              </div>
            </div>
            <v-img
              height="100px"
              width="50px"
              :contain="true"
              :src="`${item.product.image.mediaLink}/350`"
              alt=""
            />
          </div>
        </template>
        <!--end::Item-->
        <div class="separator separator-solid"></div>
      </perfect-scrollbar>
    </div>

    <!--begin::Summary-->
    <div class="p-8">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <span class="font-weight-bold text-muted font-size-sm mr-2">Total</span>
        <span class="font-weight-bolder text-dark-50 text-right"
          >{{ cart.total.toFixed(2) }}€</span
        >
      </div>
      <div class="text-right">
        <router-link :to="`/${businessCode}/cart`">
          <button type="button" class="btn btn-primary text-weight-bold">
            Pedir
          </button>
        </router-link>
      </div>
    </div>
    <!--end::Summary-->
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import QuantitySelector from "../../../content/widgets/QuantitySelector";

export default {
  name: "KTDropdownMyCart",
  components: { QuantitySelector },
  computed: {
    ...mapGetters(["cart", "business"]),
    backgroundImage() {
      return process.env.VUE_APP_API_URL + "/media/misc/bg-1.jpg";
    },
    businessCode() {
      if (this.business) {
        return this.business.code;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped>
.ps > .ps__scrollbar-x-rail,
.ps > .ps__scrollbar-y-rail {
  opacity: 0.6;
}
</style>
